(function ($) {
  $.fn.extend({
    audioPlayer: function (options) {
      var opt = options;

      function setTimer(du) {
        var time = Math.floor(du);

        if (time < 60) {
          return '00:' + (time < 10 ? '0' : '') + time;
        } else {
          var min = Math.floor(time / 60);
          var sec = time % 60;
          return (min < 10 ? '0' : '') + min + ':' + (sec < 10 ? '0' : '') + sec;
        }
      }

      $(this).each(function () {
        // const AudioContext = window.AudioContext || window.webkitAudioContext;
        // const audioContext = new AudioContext();
        const audioElement = this;
        // const track = audioContext.createMediaElementSource(audioContext.destination);
        var container = $(this).parents('.audio-player');
        var stop = container.find('.stop');
        var play = container.find('.play');
        var progress = container.find('.bar');
        var current = container.find('.current');
        var duration = container.find('.duration');

        audioElement.onloadeddata = function () {
          duration.html(setTimer(this.duration));
          current.html('00:00');
        };

        stop.click(function () {
          if (!$(this).hasClass('active')) return;
          audioElement.stop();
          play[0].dataset.playing = 'false';
        });

        play.click(function () {
          if (this.dataset.playing === 'false') {
            audioElement.play();
            stop.addClass('active');
            this.dataset.playing = 'true';
          } else if (this.dataset.playing === 'true') {
            audioElement.pause();
            stop.removeClass('active');
            this.dataset.playing = 'false';
          }
        });

        audioElement.onended = function () {
          stop.removeClass('active');
          play[0].dataset.playing = 'false';
        };

        audioElement.ontimeupdate = function () {
          if (audioElement.currentTime > 0) {
            var value = 0;
            // debugger;
            value = Math.floor((100 / audioElement.duration) * audioElement.currentTime);
            current.html(setTimer(audioElement.currentTime));
            progress[0].style.width = value + '%';
          } else {
            current.html('00:00');
            progress[0].style.width = 0;
          }
        };
      });
      // debugger;
    },
  });
})(jQuery);

const navs = [
  '#overview',
  '#the-concept',
  '#identity',
  '#tone-of-voice',
  '#passenger-app',
  '#drive-app',
  '#the-credits',
];
let hashIndex = setHashIndex();
let isLoading = true;
let isTransition = false;
let currentTop = 0;
let setMaquee = false;
const scroller =
  document.scrollingElement || document.documentElement || document.body.parentNode || document.body;

function SmoothScroll(target, speed, smooth) {
  if (target === document)
    target =
      document.scrollingElement || document.documentElement || document.body.parentNode || document.body; // cross browser support for document scrolling

  var moving = false;
  var pos = target.scrollTop;
  var frame = target === document.body && document.documentElement ? document.documentElement : target; // safari is the new IE

  target.addEventListener('mousewheel', scrolled, { passive: false });
  target.addEventListener('DOMMouseScroll', scrolled, { passive: false });

  function scrolled(e) {
    e.preventDefault(); // disable default scrolling

    var delta = normalizeWheelDelta(e);

    pos += -delta * speed;
    pos = Math.max(0, Math.min(pos, target.scrollHeight - frame.clientHeight)); // limit scrolling

    if (!moving) update();
  }

  function normalizeWheelDelta(e) {
    if (e.detail) {
      if (e.wheelDelta) return (e.wheelDelta / e.detail / 40) * (e.detail > 0 ? 1 : -1);
      // Opera
      else return -e.detail / 3; // Firefox
    } else return e.wheelDelta / 120; // IE,Safari,Chrome
  }

  function update() {
    moving = true;

    var delta = (pos - target.scrollTop) / smooth;

    target.scrollTop += delta;

    if (Math.abs(delta) > 0.5) requestFrame(update);
    else moving = false;
  }

  var requestFrame = (function () {
    // requestAnimationFrame cross browser
    return (
      window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.oRequestAnimationFrame ||
      window.msRequestAnimationFrame ||
      function (func) {
        window.setTimeout(func, 1000 / 50);
      }
    );
  })();
}

function setHashIndex(hash) {
  return !hash && !window.location.hash ? 0 : navs.indexOf(hash || window.location.hash);
}

function toggleMobile() {
  if (window.innerWidth >= 1024) return;
  var btn = $('.nav-trigger');
  if (btn.hasClass('is-open')) {
    btn.addClass('is-closing');
    setTimeout(function () {
      btn.attr('class', 'nav-trigger');
    }, 700);
    $('body').removeClass('is-menuopen');
  } else {
    btn.addClass('is-opening');
    setTimeout(function () {
      btn.addClass('is-open');
      btn.removeClass('is-opening');
    }, 700);
    $('body').addClass('is-menuopen');
  }
}
// new SmoothScroll(scroller, 50, 8);

// $(navs[hashIndex]).addClass(');
$('article').addClass('is-ready');
$('main').imagesLoaded(function () {
  anime({
    targets: '.loading',
    translateX: '100%',
    skewX: '-30deg',
    easing: 'easeInOutQuad',
    duration: 1000,
    complete: function () {
      isLoading = false;
      if (!!window.location.hash) {
        currentTop = $(navs[hashIndex]).position().top;
        $(scroller).animate(
          {
            scrollTop: currentTop,
          },
          0,
          function () {
            scroller.scrollTop = currentTop;
            $('body').removeClass('is-loading');
          },
        );
      } else {
        $('body').removeClass('is-loading');
      }
    },
  });
  articleFadeIn();
});

$('nav a.nav-item').click(function (e) {
  // e.preventDefault();

  let hash = $(this).attr('href');
  $(this).addClass('active').siblings('.active').removeClass('active');
  newIndex = setHashIndex(hash);

  if (newIndex === hashIndex) return;
  else hashIndex = newIndex;

  isTransition = true;
  $('body').addClass('is-transition');

  const dest = $(hash).position().top;
  currentTop = dest;
  toggleMobile();

  $(scroller).animate(
    {
      scrollTop: dest,
    },
    500,
    function () {
      // console.log('target: ' + target.scrollTop);
      scroller.scrollTop = currentTop;
      $('body').removeClass('is-transition');
      isTransition = false;
    },
  );
});

var animation = lottie.loadAnimation({
  container: $('.lottie.is-start')[0], // 裝動畫的容器
  renderer: 'svg',
  loop: true,
  autoplay: true,
  path: '//yoxi-case-study.aja.com.tw/video/yoxi-start.json', // 動畫json 檔
});

var animation2 = lottie.loadAnimation({
  container: $('.lottie.loading-container')[0], // 裝動畫的容器
  renderer: 'svg',
  loop: true,
  autoplay: true,
  path: '//yoxi-case-study.aja.com.tw/video/loading.json', // 動畫json 檔
});

// window.addEventListener('hashchange', function (e) {
//   e.preventDefault();
//   $(scroller).scrollTop(currentTop);
//   return false;
// });
var scrollTimeout = null;
$(window).on('scroll', wheelDetect);
// $(scroller).on('mousewheel', wheelDetect);
$('audio').audioPlayer();

function wheelDetect(e) {
  if (isTransition) return;
  if (window.innerWidth <= 768) {
    if ($(window).scrollTop() > currentTop) {
      $('header').addClass('slide-to-hide');
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(function () {
        clearTimeout(scrollTimeout);
        $('header').removeClass('slide-to-hide');
      }, 5000);
    } else {
      $('header').removeClass('slide-to-hide');
    }
  }
  currentTop = $(window).scrollTop();

  $(navs).each(function (index, nav) {
    if (
      currentTop > $(nav).position().top - 100 &&
      ((index + 1 < navs.length && currentTop < $(navs[index + 1]).position().top - window.innerHeight / 2) ||
        currentTop < $(nav).position().top + $(nav).height())
    ) {
      // if (nav === '#drive-app' && !setMaquee) {
      //   setMaquee = true;
      // }
      if (nav === '#passenger-app') {
        $('.brand').addClass('is-reverse');
      } else {
        $('.brand').removeClass('is-reverse');
      }
      $('[href="' + nav + '"]')
        .addClass('active')
        .siblings('.active')
        .removeClass('active');
      $('main').attr('class', 'with-scroller ' + nav.replace('#', 'is-'));
    }
  });

  let ch = $('#the-credits').height();
  let dist = $('#the-credits').offset().top - ch;
  if (currentTop - dist > 0) {
    let y = 1 - (currentTop - dist) / ch;
    $('.credit-wrapper').attr('style', 'transform: translateY(' + (-100 * y > 0 ? 0 : -100 * y) + 'px);');
  }

  $('video').each(function (index, dom) {
    var offset = $(dom).offset().top;
    if (offset - 100 > currentTop && currentTop + window.innerHeight > offset + $(dom).height() + 100) {
      dom.play();
    } else {
      dom.pause();
    }
  });

  $('.scroll-to-zoom').each(function (index, dom) {
    var offset = $(dom).offset().top;
    if (
      offset - 100 > currentTop &&
      currentTop + window.innerHeight > offset + $(dom).height() + 100 &&
      !$(dom).hasClass('is-zooming')
    ) {
      $(dom).addClass('is-zooming');
    }
  });
}

$('.credit-list').marquee();

function articleFadeIn() {
  anime({
    targets: 'article section',
    keyframes: [
      {
        translateX: '-30%',
        opacity: 0,
      },
      {
        translateX: '0%',
        opacity: 1,
      },
    ],
    delay: anime.stagger(200),
    easing: 'easeInOutQuad',
    duration: 800,
    complete: function () {
      $('body').removeClass('is-transition');
      // $(navs[hashIndex]).addClass('is-visible');
      $('article').removeClass('is-ready');
    },
  });
}

$('.nav-trigger').click(toggleMobile);

var slideshows = $('#icon-indicator').on('cycle-next cycle-prev', function (e, opts) {
  // advance the other slideshow
  slideshows.not(this).cycle('goto', opts.currSlide);
});

$('#icon-indicator > .icon').click(function () {
  var index = $('.icon-indicator > .icon').index(this);
  slideshows.cycle('goto', index);
});
